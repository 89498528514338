@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Galada';
    src: url('./assets/fonts/Galada-Regular.woff2') format('woff2'),
        url('./assets/fonts/Galada-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

a {
   @apply underline text-blue-600 hover:text-blue-800 visited:text-purple-600
}